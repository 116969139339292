(function(){
    
    var textNodes = [];


    function setStart(node){
        /*Only Text Nodes*/
        if(node.nodeType != 3){
            var childs = node.childNodes;
            if(childs.length > 0){
                var l = childs.length;
                for (var i = 0; i < l; i++) {
                    setStart(childs[i]);
                }
            }
            return;
        }

        var value = node.nodeValue;
        if(value.trim().length == 0){ return;}

        node.nodeValue = "";

        var s = document.createElement("span");
        s.endText = value;
        s.endlength = value.length;
        s.innerHTML = value;

        node.parentNode.insertBefore(s, node);
        textNodes.push(s);
    }

    function animate(idx, step){
        step = step || 0;

        var  n = "";
        var value = textNodes[idx].endText;
        var l = textNodes[idx].endText.length;
        var real = Math.ceil(l / 10 * step);

        for (var i = 0; i < l; i++) {
            if(i <= real && value[i]){
                n += value[i];
                continue;
            }

            if(value[i].trim() == ""){
                n += " ";
                continue;
            }

            n += Math.random().toString(36).substring(3, 4);
        }

        textNodes[idx].innerHTML = n;

        if(step < 10){
            setTimeout(animate, 120, idx, step + 1);
        } else {
            textNodes[idx].innerHTML =  textNodes[idx].endText;
        }
    }

    setStart(document.getElementById("main"));

    var l = textNodes.length;

    var s = 0;
    for (var i = 0; i < l; i++) {
        setTimeout(animate, s, i);
        //if (textNodes[i].endlength > 40){
        //     s += 150;
        //}
    }

    var mail = document.getElementById("mail");
    if(mail){
        setTimeout(function(){
            var emailE = 'falk-m.de'

            emailE = ('&#x69;&#x6D;&#x70;&#x72;&#x65;&#x73;&#x73;&#x75;&#x6D;' + '&#x40;' + emailE)
            mail.innerHTML = emailE;
        }, 1000); 
    }
})()
